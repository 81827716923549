import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UrlShortener from './components/url_shortener';
import RedirectExample from './components/redirect';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        {UrlShortener()}
      </header>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path=":tag" element={<RedirectExample />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
