import {React, useEffect} from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import Axios from 'axios';
import { API_ENDPOINT, APP_URL } from './constants';


export default function RedirectExample() {
    // let [searchParams, setSearchParams] = useSearchParams();
    // console.log(searchParams.get("id"));
    let { tag } = useParams();

    const shortenUrl = async (tag) => {
        const urlB = `${API_ENDPOINT}/api/url/${tag}`;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "a",
        }
        console.log(JSON.stringify(headers));
        const response = await Axios.get(urlB, {headers});
        return response.data.long_url;
    };
    
    console.log(tag);
    useEffect(() => {
      const timeout = setTimeout(async () => {
        // redirects to an external URL
        const url = await shortenUrl(tag);
        window.location.replace(url);
      }, 10);
  
      return () => clearTimeout(timeout);
    }, []);

    return <>Redirecting...</>;
}