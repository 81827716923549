import {React, useState, useRef} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar'
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyToClipboardButton from './copy';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PageviewIcon from '@mui/icons-material/Pageview';
import BasicTable from './url_table';
import Axios from 'axios';
import { API_ENDPOINT, APP_URL } from './constants';

export default function Types() {
    // const valueRefOutput = useRef('');
    const valueRefInput = useRef('');
    const [url, setUrl] = useState('');
    const [open, setOpen] = useState(false);
    const [shortUrl, setShortUrl] = useState('');
    const [shortLink, setShortLink] = useState('');

    const handleClick = () => {
      setOpen(true)
      navigator.clipboard.writeText(shortUrl);
    }

    const shortL = (shortUrl) => {
        return (
            <a href={shortUrl}>{shortUrl}</a>
        )
    }

    const onClearClicked = () => {
        valueRefInput.current.value = '';
        // valueRefOutput.current.value = '';
        let a = shortL('');
        setShortLink(a);
    };

    const viewTable = (row) => {
        const rows = [{
            url: 'https://j.lk',
            shortUrl: 'https://haha.lk',
            clicks: 13
        }];
        console.log(JSON.stringify(row));
        if (row) {
            return BasicTable([row]);
        }
        return (<p/>);
    }

    const [table, setTable] = useState(viewTable({}));
    // const table = viewTable();

    const shortenUrl = async (url) => {
        const urlB = `${API_ENDPOINT}/api/url`;
        const input = {
            "long_url":url
        };
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "a"
        }
        const response = await Axios.post(urlB, input, {headers: headers});
        // console.log(JSON.stringify(response.data));
        let tab = viewTable(response.data);
        setTable(tab);
        return `https://bitly.pages.dev/${response.data.tag}`;
    };

    const onShortenClicked = async () => {
        let short = await shortenUrl(valueRefInput.current.value);
        setShortUrl(short);
        // valueRefOutput.current.value = short;
        let sh = shortL(short);
        setShortLink(sh);
        console.log(short);
    };


    return (
        <div>
      <Box sx={{ width: '100%', maxWidth: 500 }}>
        <Typography variant="h3" gutterBottom>
          URL Shortener Demo
        </Typography>
        <TextField
          required
          id="outlined-required"
          label="Enter URL here"
          defaultValue="https://google.com"
          inputRef={valueRefInput}
        />
        </Box>
        <p></p>
        {/* {'       '}
        <TextField
          required
          id="outlined-required"
          label="Shortened URL"
          defaultValue=" "
          inputRef={valueRefOutput}
        />
        <p></p> */}
        {shortLink}
        <p></p>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* <Item> */}
                        <Button variant="contained" endIcon={<SendIcon />} onClick={onShortenClicked}>
                            Shorten
                        </Button> 
                    {/* </Item> */}
                </Grid>
                <Grid item xs={4}>
                    {/* <Item> */}
                    <Button onClick={handleClick} variant="contained" endIcon={<ContentCopyIcon />}>Copy</Button>
                        <Snackbar
                            open={open}
                            onClose={() => setOpen(false)}
                            autoHideDuration={2000}
                            message="Copied to clipboard"
                        />  
                    {/* </Item> */}
                </Grid>
                <Grid item xs={4}>
                    {/* <Item> */}
                        <Button variant="contained" endIcon={<DeleteIcon />} align='center' onClick={onClearClicked}>
                            Clear
                        </Button>
                    {/* </Item> */}
                </Grid>
            </Grid>
            <p></p>
            {/* <Button variant="contained" endIcon={<PageviewIcon />} onClick={onShortenClicked}>
                View
            </Button>  */}
            <p></p>
            {table}
        </Box>
        </div>
    );
  }